const DEV = {
  CPAAS_ASSETS: 'https://cpaas-assets-hlg.lan.zenvia360.com',
  ASSETS: 'https://app-dsv.lan.zenvia360.com',
  CONVERSION_ASSETS: 'https://conversion-assets-development.lan.zenvia360.com',
  CATALOGS_ASSETS: 'https://catalogs-mfe-development.lan.zenvia360.com',
}
const DRP = {
  ASSETS: 'https://dr-cpaas-assets.zenvia.com',
  CONVERSION_ASSETS: 'https://conversion-assets.zenvia.com',
  CATALOGS_ASSETS: 'https://catalogs-mfe.zenvia.com',
}

const HLG = {
  ASSETS: 'https://cpaas-assets-hlg.lan.zenvia360.com',
  CONVERSION_ASSETS: 'https://conversion-assets-staging.lan.zenvia360.com',
  CATALOGS_ASSETS: 'https://catalogs-mfe-staging.lan.zenvia360.com',
}

const PRD = {
  ASSETS: 'https://cpaas-assets.zenvia.com',
  CONVERSION_ASSETS: 'https://conversion-assets.zenvia.com',
  CATALOGS_ASSETS: 'https://catalogs-mfe.zenvia.com',
}

const LOCAL = {
  URL: 'http://localhost:',
}

const DEPENDENCIES_ATTRACTION = [
  {
    name: 'campaign-sidebar',
    urlDev: `${DEV.ASSETS}/campaign-sidebar/app.js`,
    urlDrp: `${DRP.ASSETS}/campaign-sidebar/app.js`,
    urlHlg: `${HLG.ASSETS}/campaign-sidebar/app.js`,
    urlPrd: `${PRD.ASSETS}/campaign-sidebar/app.js`,
    urlLocal: `${LOCAL.URL}` + '8093' + '/app.js',
  },
  {
    name: 'contacts',
    urlDev: `${DEV.ASSETS}/contacts/app.js`,
    urlDrp: `${DRP.ASSETS}/contacts/app.js`,
    urlHlg: `${HLG.ASSETS}/contacts/app.js`,
    urlPrd: `${PRD.ASSETS}/contacts/app.js`,
    urlLocal: `${LOCAL.URL}` + '8094' + '/app.js',
  },
  {
    name: 'campaign-automation',
    urlDev: `${DEV.ASSETS}/campaign-automation/app.js`,
    urlDrp: `${DRP.ASSETS}/campaign-automation/app.js`,
    urlHlg: `${HLG.ASSETS}/campaign-automation/app.js`,
    urlPrd: `${PRD.ASSETS}/campaign-automation/app.js`,
    urlLocal: `${LOCAL.URL}` + '8095' + '/app.js',
  },
  {
    name: 'campaign-dispatches',
    urlDev: `${DEV.ASSETS}/campaign-dispatches/app.js`,
    urlDrp: `${DRP.ASSETS}/campaign-dispatches/app.js`,
    urlHlg: `${HLG.ASSETS}/campaign-dispatches/app.js`,
    urlPrd: `${PRD.ASSETS}/campaign-dispatches/app.js`,
    urlLocal: `${LOCAL.URL}` + '8101' + '/app.js',
  },
  {
    name: 'builder',
    urlDev: `${DEV.ASSETS}/builder/app.js`,
    urlDrp: `${DRP.ASSETS}/builder/app.js`,
    urlHlg: `${HLG.ASSETS}/builder/app.js`,
    urlPrd: `${PRD.ASSETS}/builder/app.js`,
    urlLocal: `${LOCAL.URL}` + '8096' + '/app.js',
  },
  {
    name: 'broadcast',
    urlDev: `${DEV.ASSETS}/platform-broadcast/app.js`,
    urlDrp: `${DRP.ASSETS}/platform-broadcast/app.js`,
    urlHlg: `${HLG.ASSETS}/platform-broadcast/app.js`,
    urlPrd: `${PRD.ASSETS}/platform-broadcast/app.js`,
    urlLocal: `${LOCAL.URL}` + '8102' + '/app.js',
  },
  {
    name: 'broadcast-monitoring',
    urlDev: `${DEV.ASSETS}/broadcast-monitoring/app.js`,
    urlDrp: `${DRP.ASSETS}/broadcast-monitoring/app.js`,
    urlHlg: `${HLG.ASSETS}/broadcast-monitoring/app.js`,
    urlPrd: `${PRD.ASSETS}/broadcast-monitoring/app.js`,
    urlLocal: `${LOCAL.URL}` + '8103' + '/app.js',
  },
  {
    name: 'broadcast-social',
    urlDev: `${DEV.ASSETS}/broadcast-social/app.js`,
    urlDrp: `${DRP.ASSETS}/broadcast-social/app.js`,
    urlHlg: `${HLG.ASSETS}/broadcast-social/app.js`,
    urlPrd: `${PRD.ASSETS}/broadcast-social/app.js`,
    urlLocal: `${LOCAL.URL}` + '8104' + '/app.js',
  },
  {
    name: 'social-monitoring',
    urlDev: `${DEV.ASSETS}/social-monitoring/app.umd.cjs`,
    urlDrp: `${DRP.ASSETS}/social-monitoring/app.umd.cjs`,
    urlHlg: `${HLG.ASSETS}/social-monitoring/app.umd.cjs`,
    urlPrd: `${PRD.ASSETS}/social-monitoring/app.umd.cjs`,
    urlLocal: `${LOCAL.URL}` + '8109' + '/app.umd.cjs',
  },
  {
    name: 'conversion-reports-v2',
    urlDev: `${DEV.ASSETS}/conversion-reports-v2/app.umd.cjs`,
    urlDrp: `${DRP.ASSETS}/conversion-reports-v2/app.umd.cjs`,
    urlHlg: `${HLG.ASSETS}/conversion-reports-v2/app.umd.cjs`,
    urlPrd: `${PRD.ASSETS}/conversion-reports-v2/app.umd.cjs`,
    urlLocal: `${LOCAL.URL}` + '8108' + '/app.umd.cjs',
  },
  {
    name: 'conversion-activities-v2',
    urlDev: `${DEV.CONVERSION_ASSETS}/conversion-activities.umd.cjs`,
    urlDrp: `${DRP.CONVERSION_ASSETS}/conversion-activities.umd.cjs`,
    urlHlg: `${HLG.CONVERSION_ASSETS}/conversion-activities.umd.cjs`,
    urlPrd: `${PRD.CONVERSION_ASSETS}/conversion-activities.umd.cjs`,
    urlLocal: `${LOCAL.URL}` + '5175' + '/conversion-activities.umd.cjs',
  },

  // legacy project
  {
    name: 'notifications',
    urlDev: `${DEV.CPAAS_ASSETS}/notifications/app.js`,
    urlDrp: `${DRP.ASSETS}/notifications/app.js`,
    urlHlg: `${HLG.ASSETS}/notifications/app.js`,
    urlPrd: `${PRD.ASSETS}/notifications/app.js`,
    urlLocal: `${LOCAL.URL}` + '8081' + '/app.js',
  },
]

const DEPENDENCIES_APP = [
  {
    name: 'selfservice',
    urlDev: `${DEV.ASSETS}/selfservice/main-es5.js`,
    urlDrp: `${DRP.ASSETS}/selfservice/main-es5.js`,
    urlHlg: `${HLG.ASSETS}/selfservice/main-es5.js`,
    urlPrd: `${PRD.ASSETS}/selfservice/main-es5.js`,
    urlLocal: `${LOCAL.URL}` + '4201' + '/main.js',
  },
  {
    name: 'navbar-v2',
    urlDev: `${DEV.ASSETS}/navbar-v2/app.js`,
    urlDrp: `${DRP.ASSETS}/navbar-v2/app.js`,
    urlHlg: `${HLG.ASSETS}/navbar-v2/app.js`,
    urlPrd: `${PRD.ASSETS}/navbar-v2/app.js`,
    urlLocal: `${LOCAL.URL}` + '8101' + '/app.js',
  },
  {
    name: 'components',
    urlDev: `${DEV.ASSETS}/components/js/app.js`,
    urlDrp: `${DRP.ASSETS}/components/js/app.js`,
    urlHlg: `${HLG.ASSETS}/components/js/app.js`,
    urlPrd: `${PRD.ASSETS}/components/js/app.js`,
    urlLocal: `${LOCAL.URL}` + '8082' + '/app.js',
  },
  {
    name: 'channel-sms',
    urlDev: `${DEV.ASSETS}/channel-sms/app.js`,
    urlDrp: `${DRP.ASSETS}/channel-sms/app.js`,
    urlHlg: `${HLG.ASSETS}/channel-sms/app.js`,
    urlPrd: `${PRD.ASSETS}/channel-sms/app.js`,
    urlLocal: `${LOCAL.URL}` + '8085' + '/app.js',
  },
  {
    name: 'channel-messenger',
    urlDev: `${DEV.ASSETS}/channel-messenger/app.js`,
    urlDrp: `${DRP.ASSETS}/channel-messenger/app.js`,
    urlHlg: `${HLG.ASSETS}/channel-messenger/app.js`,
    urlPrd: `${PRD.ASSETS}/channel-messenger/app.js`,
    urlLocal: `${LOCAL.URL}` + '8086' + '/app.js',
  },
  {
    name: 'channel-voice',
    urlDev: `${DEV.ASSETS}/channel-voice/app.js`,
    urlDrp: `${DRP.ASSETS}/channel-voice/app.js`,
    urlHlg: `${HLG.ASSETS}/channel-voice/app.js`,
    urlPrd: `${PRD.ASSETS}/channel-voice/app.js`,
    urlLocal: `${LOCAL.URL}` + '8090' + '/app.js',
  },
  {
    name: 'channel-whatsapp',
    urlDev: `${DEV.ASSETS}/channel-whatsapp/app.js`,
    urlDrp: `${DRP.ASSETS}/channel-whatsapp/app.js`,
    urlHlg: `${HLG.ASSETS}/channel-whatsapp/app.js`,
    urlPrd: `${PRD.ASSETS}/channel-whatsapp/app.js`,
    urlLocal: `${LOCAL.URL}` + '8089' + '/app.js',
  },
  {
    name: 'templates',
    urlDev: `${DEV.ASSETS}/templates/app.js`,
    urlDrp: `${DRP.ASSETS}/templates/app.js`,
    urlHlg: `${HLG.ASSETS}/templates/app.js`,
    urlPrd: `${PRD.ASSETS}/templates/app.js`,
    urlLocal: `${LOCAL.URL}` + '8105' + '/app.js',
  },
  {
    name: 'channel-webchat',
    urlDev: `${DEV.ASSETS}/channel-webchat/app.js`,
    urlDrp: `${DRP.ASSETS}/channel-webchat/app.js`,
    urlHlg: `${HLG.ASSETS}/channel-webchat/app.js`,
    urlPrd: `${PRD.ASSETS}/channel-webchat/app.js`,
    urlLocal: `${LOCAL.URL}` + '8087' + '/app.js',
  },
  {
    name: 'channel-rcs',
    urlDev: `${DEV.ASSETS}/channel-rcs/app.js`,
    urlDrp: `${DRP.ASSETS}/channel-rcs/app.js`,
    urlHlg: `${HLG.ASSETS}/channel-rcs/app.js`,
    urlPrd: `${PRD.ASSETS}/channel-rcs/app.js`,
    urlLocal: `${LOCAL.URL}` + '8091' + '/app.js',
  },
  {
    name: 'home',
    urlDev: `${DEV.ASSETS}/cpaas-home/app.js`,
    urlDrp: `${DRP.ASSETS}/cpaas-home/app.js`,
    urlHlg: `${HLG.ASSETS}/cpaas-home/app.js`,
    urlPrd: `${PRD.ASSETS}/cpaas-home/app.js`,
    urlLocal: `${LOCAL.URL}` + '8088' + '/app.js',
  },
  {
    name: 'customer-area',
    urlDev: 'https://customer-area-hlg.lan.zenvia360.com/app.js',
    urlDrp: 'https://dr-customer-area.zenvia.com/app.js',
    urlHlg: 'https://customer-area-hlg.lan.zenvia360.com/app.js',
    urlPrd: 'https://customer-area.zenvia.com/app.js',
    urlLocal: `${LOCAL.URL}9090` + '/app.js',
  },
  {
    name: 'flows',
    urlDev: `${DEV.ASSETS}/flows/main-es5.js`,
    urlDrp: `${DRP.ASSETS}/flows/main-es5.js`,
    urlHlg: `${HLG.ASSETS}/flows/main-es5.js`,
    urlPrd: `${PRD.ASSETS}/flows/main-es5.js`,
    urlLocal: `${LOCAL.URL}` + '8084' + '/main-es5.js',
  },
  {
    name: 'organizations',
    urlDev: `${DEV.CPAAS_ASSETS}/platform-organizations/app.js`,
    urlDrp: `${DRP.ASSETS}/platform-organizations/app.js`,
    urlHlg: `${HLG.ASSETS}/platform-organizations/app.js`,
    urlPrd: `${PRD.ASSETS}/platform-organizations/app.js`,
    urlLocal: `${LOCAL.URL}` + '8092' + '/app.js',
  },
  {
    name: 'channel-instagram',
    urlDev: `${DEV.ASSETS}/channel-instagram/app.js`,
    urlDrp: `${DRP.ASSETS}/channel-instagram/app.js`,
    urlHlg: `${HLG.ASSETS}/channel-instagram/app.js`,
    urlPrd: `${PRD.ASSETS}/channel-instagram/app.js`,
    urlLocal: `${LOCAL.URL}` + '8097' + '/app.js',
  },
  {
    name: 'platform-report',
    urlDev: `${DEV.CPAAS_ASSETS}/platform-report/app.js`,
    urlDrp: `${DRP.ASSETS}/platform-report/app.js`,
    urlHlg: `${HLG.ASSETS}/platform-report/app.js`,
    urlPrd: `${PRD.ASSETS}/platform-report/app.js`,
    urlLocal: `${LOCAL.URL}` + '8099' + '/app.js',
  },
  {
    name: 'labs',
    urlDev: `${DEV.CPAAS_ASSETS}/zlabs-showcase/platform-labs.js`,
    urlDrp: `${DRP.ASSETS}/zlabs-showcase/platform-labs.js`,
    urlHlg: `${HLG.ASSETS}/zlabs-showcase/platform-labs.js`,
    urlPrd: `${PRD.ASSETS}/zlabs-showcase/platform-labs.js`,
    urlLocal: `${LOCAL.URL}` + '8500' + '/platform-labs.js',
  },
  {
    name: 'platform-errors-page',
    urlDev: `${DEV.CPAAS_ASSETS}/platform-errors-page/app.js`,
    urlDrp: `${DRP.ASSETS}/platform-errors-page/app.js`,
    urlHlg: `${HLG.ASSETS}/platform-errors-page/app.js`,
    urlPrd: `${PRD.ASSETS}/platform-errors-page/app.js`,
    urlLocal: `${LOCAL.URL}` + '8100' + '/app.js',
  },
  {
    name: 'home-v2',
    urlDev: `${DEV.ASSETS}/home-v2/app.js`,
    urlDrp: `${DRP.ASSETS}/home-v2/app.js`,
    urlHlg: `${HLG.ASSETS}/home-v2/app.js`,
    urlPrd: `${PRD.ASSETS}/home-v2/app.js`,
    urlLocal: `${LOCAL.URL}` + '8100' + '/app.js',
  },
  {
    name: 'feedback-service',
    urlDev: `${DEV.CPAAS_ASSETS}/feedback-service/index.js`,
    urlDrp: `${DRP.ASSETS}/feedback-service/index.js`,
    urlHlg: `${HLG.ASSETS}/feedback-service/index.js`,
    urlPrd: `${PRD.ASSETS}/feedback-service/index.js`,
    urlLocal: `${LOCAL.URL}` + '8110' + '/index.js',
  },
  {
    name: 'channel-v2',
    urlDev: `${DEV.ASSETS}/channel-v2/app.js`,
    urlDrp: `${DRP.ASSETS}/channel-v2/app.js`,
    urlHlg: `${HLG.ASSETS}/channel-v2/app.js`,
    urlPrd: `${PRD.ASSETS}/channel-v2/app.js`,
    urlLocal: `${LOCAL.URL}` + '8111' + '/app.js',
  },
  {
    name: 'customer-data-service',
    urlDev: `${DEV.CPAAS_ASSETS}/customer-data-service/index.js`,
    urlDrp: `${DRP.ASSETS}/customer-data-service/index.js`,
    urlHlg: `${HLG.ASSETS}/customer-data-service/index.js`,
    urlPrd: `${PRD.ASSETS}/customer-data-service/index.js`,
    urlLocal: `${LOCAL.URL}` + '8112' + '/index.js',
  },
]

const DEPENDENCIES_SUITE = [
  {
    name: 'zsuite-sidebar',
    urlDev: `${DEV.ASSETS}/zsuite-sidebar/zsuite-sidebar.js`,
    urlDrp: `${DRP.ASSETS}/zsuite-sidebar/zsuite-sidebar.js`,
    urlHlg: `${HLG.ASSETS}/zsuite-sidebar/zsuite-sidebar.js`,
    urlPrd: `${PRD.ASSETS}/zsuite-sidebar/zsuite-sidebar.js`,
    urlLocal: `${LOCAL.URL}` + '9001' + '/zsuite-sidebar.js',
  },
  {
    name: 'zsuite-navbar',
    urlDev: `${DEV.ASSETS}/zsuite-navbar/zsuite-navbar.js`,
    urlDrp: `${DRP.ASSETS}/zsuite-navbar/zsuite-navbar.js`,
    urlHlg: `${HLG.ASSETS}/zsuite-navbar/zsuite-navbar.js`,
    urlPrd: `${PRD.ASSETS}/zsuite-navbar/zsuite-navbar.js`,
    urlLocal: `${LOCAL.URL}` + '9002' + '/zsuite-navbar.js',
  },
  {
    name: 'zsuite-reports',
    urlDev: `${DEV.ASSETS}/zsuite-reports/zsuite-reports.js`,
    urlDrp: `${DRP.ASSETS}/zsuite-reports/zsuite-reports.js`,
    urlHlg: `${HLG.ASSETS}/zsuite-reports/zsuite-reports.js`,
    urlPrd: `${PRD.ASSETS}/zsuite-reports/zsuite-reports.js`,
    urlLocal: `${LOCAL.URL}` + '9003' + '/zsuite-reports.js',
  },
  {
    name: 'zsuite-home',
    urlDev: `${DEV.ASSETS}/zsuite-home/app.js`,
    urlDrp: `${DRP.ASSETS}/zsuite-home/app.js`,
    urlHlg: `${HLG.ASSETS}/zsuite-home/app.js`,
    urlPrd: `${PRD.ASSETS}/zsuite-home/app.js`,
    urlLocal: `${LOCAL.URL}` + '8114' + '/app.js',
  },
  {
    name: 'zsuite-onboarding',
    urlDev: `${DEV.ASSETS}/zsuite-onboarding/app.js`,
    urlDrp: `${DRP.ASSETS}/zsuite-onboarding/app.js`,
    urlHlg: `${HLG.ASSETS}/zsuite-onboarding/app.js`,
    urlPrd: `${PRD.ASSETS}/zsuite-onboarding/app.js`,
    urlLocal: `${LOCAL.URL}` + '8115' + '/app.js',
  },
  {
    name: 'zsuite-marketplace',
    urlDev: `${DEV.ASSETS}/zsuite-marketplace/app.js`,
    urlDrp: `${DRP.ASSETS}/zsuite-marketplace/app.js`,
    urlHlg: `${HLG.ASSETS}/zsuite-marketplace/app.js`,
    urlPrd: `${PRD.ASSETS}/zsuite-marketplace/app.js`,
    urlLocal: `${LOCAL.URL}` + '8118' + '/app.js',
  },
  {
    name: 'conversion-inbox',
    urlDev: `${DEV.CONVERSION_ASSETS}/conversion-inbox.umd.cjs`,
    urlDrp: `${DRP.CONVERSION_ASSETS}/conversion-inbox.umd.cjs`,
    urlHlg: `${HLG.CONVERSION_ASSETS}/conversion-inbox.umd.cjs`,
    urlPrd: `${PRD.CONVERSION_ASSETS}/conversion-inbox.umd.cjs`,
    urlLocal: `${LOCAL.URL}` + '5173' + '/conversion-inbox.umd.cjs',
  },
  {
    name: 'conversion-inbox-sidebar',
    urlDev: `${DEV.CONVERSION_ASSETS}/conversion-inbox-sidebar.umd.cjs`,
    urlDrp: `${DRP.CONVERSION_ASSETS}/conversion-inbox-sidebar.umd.cjs`,
    urlHlg: `${HLG.CONVERSION_ASSETS}/conversion-inbox-sidebar.umd.cjs`,
    urlPrd: `${PRD.CONVERSION_ASSETS}/conversion-inbox-sidebar.umd.cjs`,
    urlLocal: `${LOCAL.URL}` + '5178' + '/conversion-inbox-sidebar.umd.cjs',
  },
  {
    name: 'conversion-settings',
    urlDev: `${DEV.CONVERSION_ASSETS}/conversion-settings.umd.cjs`,
    urlDrp: `${DRP.CONVERSION_ASSETS}/conversion-settings.umd.cjs`,
    urlHlg: `${HLG.CONVERSION_ASSETS}/conversion-settings.umd.cjs`,
    urlPrd: `${PRD.CONVERSION_ASSETS}/conversion-settings.umd.cjs`,
    urlLocal: `${LOCAL.URL}` + '5174' + '/conversion-settings.umd.cjs',
  },
  {
    name: 'conversion-activities',
    urlDev: `${DEV.CONVERSION_ASSETS}/conversion-activities.umd.cjs`,
    urlDrp: `${DRP.CONVERSION_ASSETS}/conversion-activities.umd.cjs`,
    urlHlg: `${HLG.CONVERSION_ASSETS}/conversion-activities.umd.cjs`,
    urlPrd: `${PRD.CONVERSION_ASSETS}/conversion-activities.umd.cjs`,
    urlLocal: `${LOCAL.URL}` + '5175' + '/conversion-activities.umd.cjs',
  },
  {
    name: 'conversion-integrations',
    urlDev: `${DEV.CONVERSION_ASSETS}/conversion-integrations.umd.cjs`,
    urlDrp: `${DRP.CONVERSION_ASSETS}/conversion-integrations.umd.cjs`,
    urlHlg: `${HLG.CONVERSION_ASSETS}/conversion-integrations.umd.cjs`,
    urlPrd: `${PRD.CONVERSION_ASSETS}/conversion-integrations.umd.cjs`,
    urlLocal: `${LOCAL.URL}` + '5176' + '/conversion-integrations.umd.cjs',
  },
  {
    name: 'conversion-reports',
    urlDev: `${DEV.CONVERSION_ASSETS}/conversion-reports.umd.cjs`,
    urlDrp: `${DRP.CONVERSION_ASSETS}/conversion-reports.umd.cjs`,
    urlHlg: `${HLG.CONVERSION_ASSETS}/conversion-reports.umd.cjs`,
    urlPrd: `${PRD.CONVERSION_ASSETS}/conversion-reports.umd.cjs`,
    urlLocal: `${LOCAL.URL}` + '5177' + '/conversion-reports.umd.cjs',
  },
  {
    name: 'catalogs',
    urlDev: `${DEV.CATALOGS_ASSETS}/catalogs.umd.cjs`,
    urlDrp: `${DRP.CATALOGS_ASSETS}/catalogs.umd.cjs`,
    urlHlg: `${HLG.CATALOGS_ASSETS}/catalogs.umd.cjs`,
    urlPrd: `${PRD.CATALOGS_ASSETS}/catalogs.umd.cjs`,
    urlLocal: `${LOCAL.URL}` + '5179' + '/catalogs.umd.cjs',
  },
  {
    name: 'service-app',
    urlDev: `${DEV.CPAAS_ASSETS}/service-app/service-app.js`,
    urlDrp: `${DRP.ASSETS}/service-app/service-app.js`,
    urlHlg: `${HLG.ASSETS}/service-app/service-app.js`,
    urlPrd: `${PRD.ASSETS}/service-app/service-app.js`,
    urlLocal: `${LOCAL.URL}` + '5201' + '/service-app.js',
  },
  {
    name: 'chat-app',
    urlDev: `${DEV.CPAAS_ASSETS}/zcc-chat-app/chat-app.js`,
    urlDrp: `${DRP.ASSETS}/zcc-chat-app/chat-app.js`,
    urlHlg: `${HLG.ASSETS}/zcc-chat-app/chat-app.js`,
    urlPrd: `${PRD.ASSETS}/zcc-chat-app/chat-app.js`,
    urlLocal: `${LOCAL.URL}` + '5202' + '/chat-app.js',
  },
  {
    name: 'nutrir-automation-list',
    urlDev: `${DEV.CPAAS_ASSETS}/nutrir/nutrir-automation-list.umd.cjs`,
    urlDrp: `${DRP.ASSETS}/nutrir/nutrir-automation-list.umd.cjs`,
    urlHlg: `${HLG.ASSETS}/nutrir/nutrir-automation-list.umd.cjs`,
    urlPrd: `${PRD.ASSETS}/nutrir/nutrir-automation-list.umd.cjs`,
    urlLocal: `${LOCAL.URL}` + '8080' + '/nutrir-automation-list.umd.cjs',
  },
  {
    name: 'nutrir-automation',
    urlDev: `${DEV.CPAAS_ASSETS}/nutrir/nutrir-automation.umd.cjs`,
    urlDrp: `${DRP.ASSETS}/nutrir/nutrir-automation.umd.cjs`,
    urlHlg: `${HLG.ASSETS}/nutrir/nutrir-automation.umd.cjs`,
    urlPrd: `${PRD.ASSETS}/nutrir/nutrir-automation.umd.cjs`,
    urlLocal: `${LOCAL.URL}` + '8080' + '/nutrir-automation.umd.cjs',
  },
  {
    name: 'nutrir-success-monitor',
    urlDev: `${DEV.CPAAS_ASSETS}/nutrir/nutrir-success-monitor.umd.cjs`,
    urlDrp: `${DRP.ASSETS}/nutrir/nutrir-success-monitor.umd.cjs`,
    urlHlg: `${HLG.ASSETS}/nutrir/nutrir-success-monitor.umd.cjs`,
    urlPrd: `${PRD.ASSETS}/nutrir/nutrir-success-monitor.umd.cjs`,
    urlLocal: `${LOCAL.URL}` + '8080' + '/nutrir-success-monitor.umd.cjs',
  },
]

const DEPENDENCIES_COMMON = [
  {
    name: 'authentication',
    urlDev: `${DEV.CPAAS_ASSETS}/auth/index.js`,
    urlDrp: `${DRP.ASSETS}/auth/index.js`,
    urlHlg: `${HLG.ASSETS}/auth/index.js`,
    urlPrd: `${PRD.ASSETS}/auth/index.js`,
    urlLocal: `${LOCAL.URL}` + '1234' + '/index.js',
  },
  {
    name: 'eventbus',
    urlDev: `${DEV.CPAAS_ASSETS}/eventbus/index.js`,
    urlDrp: `${DRP.ASSETS}/eventbus/index.js`,
    urlHlg: `${HLG.ASSETS}/eventbus/index.js`,
    urlPrd: `${PRD.ASSETS}/eventbus/index.js`,
    urlLocal: `${LOCAL.URL}` + '8083' + '/index.js',
  },
  {
    name: 'feature-toggle',
    urlDev: `${DEV.CPAAS_ASSETS}/feature-toggle/index.js`,
    urlDrp: `${DRP.ASSETS}/feature-toggle/index.js`,
    urlHlg: `${HLG.ASSETS}/feature-toggle/index.js`,
    urlPrd: `${PRD.ASSETS}/feature-toggle/index.js`,
    urlLocal: `${LOCAL.URL}` + '12345' + '/index.js',
  },
  {
    name: 'nlu-commons',
    urlDev: `${DEV.CPAAS_ASSETS}/nlu-commons/mfe-nlu-commons.js`,
    urlHlg: `${HLG.ASSETS}/nlu-commons/mfe-nlu-commons.js`,
    urlPrd: `${PRD.ASSETS}/nlu-commons/mfe-nlu-commons.js`,
    urlLocal: `${LOCAL.URL}` + '8116' + '/mfe-nlu-commons.js',
  },
  {
    name: 'nlu-lite',
    urlDev: `${DEV.CPAAS_ASSETS}/nlu-lite/mfe-nlu-lite.js`,
    urlHlg: `${HLG.ASSETS}/nlu-lite/mfe-nlu-lite.js`,
    urlPrd: `${PRD.ASSETS}/nlu-lite/mfe-nlu-lite.js`,
    urlLocal: `${LOCAL.URL}` + '8113' + '/mfe-nlu-lite.js',
  },
  {
    name: 'masterbots',
    urlDev: `${DEV.CPAAS_ASSETS}/masterbots/mfe-masterbots.js`,
    urlHlg: `${HLG.ASSETS}/masterbots/mfe-masterbots.js`,
    urlPrd: `${PRD.ASSETS}/masterbots/mfe-masterbots.js`,
    urlLocal: `${LOCAL.URL}` + '8114' + '/mfe-masterbots.js',
  },
  {
    name: 'nlu-commons',
    urlDev: `${DEV.CPAAS_ASSETS}/nlu-commons/mfe-nlu-commons.js`,
    urlHlg: `${HLG.ASSETS}/nlu-commons/mfe-nlu-commons.js`,
    urlPrd: `${PRD.ASSETS}/nlu-commons/mfe-nlu-commons.js`,
    urlLocal: `${LOCAL.URL}` + '8116' + '/mfe-nlu-commons.js',
  },
  {
    name: 'web-components-vue',
    urlDev: `${DEV.CPAAS_ASSETS}/attraction-library-ui/app.js`,
    urlDrp: `${DRP.ASSETS}/attraction-library-ui/app.js`,
    urlHlg: `${HLG.ASSETS}/attraction-library-ui/app.js`,
    urlPrd: `${PRD.ASSETS}/attraction-library-ui/app.js`,
    urlLocal: `${LOCAL.URL}` + '5173' + '/dist/app.js',
  },
  {
    name: 'web-components',
    urlDev: `${DEV.CPAAS_ASSETS}/platform-web-components/platform-web-components.js`,
    urlDrp: `${DRP.ASSETS}/platform-web-components/platform-web-components.js`,
    urlHlg: `${HLG.ASSETS}/platform-web-components/platform-web-components.js`,
    urlPrd: `${PRD.ASSETS}/platform-web-components/platform-web-components.js`,
    urlLocal: `${LOCAL.URL}` + '8085' + '/build/platform-web-components.esm.js',
  },
]

const LOGS_URL = 'https://logs-api-hlg.zenvia.com'
const CUSTOM_PLATFORM_URL = 'https://custom-platform-hlg.zenvia.com'
const WEB_CHAT = 'https://static.zenvia.com/embed/js/zenvia-chat.hlg.min.js'

export default {
  DEPENDENCIES: [
    ...DEPENDENCIES_APP,
    ...DEPENDENCIES_SUITE,
    ...DEPENDENCIES_COMMON,
    ...DEPENDENCIES_ATTRACTION,
  ],
  LOGS_URL,
  CUSTOM_PLATFORM_URL,
  WEB_CHAT,
}
