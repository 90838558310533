export default function ({ singleSpa, isValidName }) {
  const validateDomainName = (apps, appName) => {
    if (!isValidName(apps, appName)) {
      const message = `domain [${appName}] is duplicated`
      throw new Error(JSON.stringify({ message: `[${process.env.APP_NAME}] > ${message}` }))
    }
  }

  return (domains, authenticated) => {
    const { apps } = domains || {}
    if (!apps || !Array.isArray(apps)) return

    try {
      apps
        .filter(({ auth }) => authenticated || !auth)
        .forEach((app) => {
          const {
            name, dependencies, rules, hideLayout = false,
          } = app
          const isSidebar = ['zsuite-sidebar', 'campaign-sidebar'].includes(name)
          validateDomainName(apps, name)
          let idToMount = hideLayout ? 'main-app-no-layout' : 'main-app'
          if (isSidebar) idToMount = 'main-sidebar'
          if (name === 'zsuite-navbar') idToMount = 'main-navbar'
          if (name === 'conversion-inbox-sidebar') idToMount = 'secondary-sidebar'
          if (name === 'conversion-activities-v2') idToMount = 'app-conversion-activities-v2'
          singleSpa.registerApplication(name, dependencies, rules, {
            domElement: document.getElementById(idToMount), // Use the main container div
          })
        })

      singleSpa.start()
    } catch (ex) {
      console.error(ex)
      throw new Error(JSON.stringify({
        message: `[${process.env.APP_NAME}] > apps registration > ${ex}`,
        error: ex.message,
      }))
    }
  }
}
