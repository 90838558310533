export default {
  BLOCKEDROUTES: [
    '/contacts',
    '/contacts/segments',
    '/broadcast/homepage',
    '/sales-chat/inbox/you?sidebar=true',
    '/sales-chat/integrations',
    '/sales-chat/settings',
    '/chatbot/list',
    '/chatbot/document',
    '/chatbot/contacts',
  ],
}
